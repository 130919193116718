<template>
    <div>
        <div class="fixed top-0 left-0 h-screen w-screen z-20 pt-10">
            <div class="fixed top-0 w-full flex justify-between text-white bg-black opacity-75">
                <div class="my-2 mx-4">{{ name }}</div>
                <div class="my-2 mx-4">
                    <a href="#" class="font-semibold text-lg" @click.prevent="$emit('close')">
                        <svg class="fill-current text-white h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/></svg>
                    </a>
                </div>
            </div>
            <div class="h-full">
                <div class="h-full overflow-auto" @click="$emit('close')">
                    <div :class="{'h-full': imageMode === IMG_MODE_HEIGHT, 'w-full': imageMode === IMG_MODE_WIDTH}">
                        <img class="block m-auto" :class="{'max-w-none': imageMode !== IMG_MODE_WIDTH, 'h-full': imageMode === IMG_MODE_HEIGHT, 'w-full': imageMode === IMG_MODE_WIDTH}" :src="src" @click.stop="changeImgMode">
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed top-0 left-0 h-full w-full bg-black opacity-75 z-10"></div>
    </div>
</template>

<script>
const IMG_MODE_FULL= 0;
const IMG_MODE_WIDTH= 1;
const IMG_MODE_HEIGHT= 2;

export default {
    name: 'PopupImage',
    props: ['name', 'src'],
    data() {
        return {
            imageMode: IMG_MODE_WIDTH,
            IMG_MODE_FULL,
            IMG_MODE_WIDTH,
            IMG_MODE_HEIGHT
        };
    },
    methods: {
        changeImgMode() {
            this.imageMode = (this.imageMode + 1) % 3;
        }
    }
}
</script>