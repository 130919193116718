<template>
    <div>
        <div class="mx-2 my-2 rounded-lg overflow-hidden bg-white transition-transform duration-500 ease-in-out">
            <div class="w-full">
                <a href="#" @click.prevent="showImage = true">
                    <img class="h-56 w-full object-none object-center bg-gray-200" :src="'/images/img-' + item.code + '.jpg'">
                </a>
                <div class="flex inline-flex">
                    <a href="#" @click.prevent="showImage = true">
                        <img class="block m-1 w-10 h-10 bg-gray-200"
                            :src="'/images/img-' + item.code + '.jpg'">
                    </a>
                    <a v-if="item.videoLink" href="#" @click.prevent="displayVideo = true">
                        <img class="block m-1 w-10 h-10 bg-gray-200" src="../resources/film.svg">
                    </a>
                </div>
            </div>
            <div class="px-2 py-2">
                <h3 class="font-bold text-xl mb-2">{{ index }}- {{item.title}}</h3>
                <div class="">
                    <p class="font-bold text-red-600 pb-3">{{ item.name }}</p>
                    <p class="text-gray-800 text-base h-32" v-html="item.description"></p>
                    <p class="pt-1 text-gray-600 text-sm">Format: <strong>{{ item.format }}</strong></p>
                    <p class="pb-3 text-gray-600">Estimation: <span class="font-bold">{{ item.estimatedPrice }}</span></p>
                </div>
                <div class="mt-2" v-show="!showOffer">
                    <button class="text-sm uppercase font-semibold tracking-wider w-full btn btn-red"
                            v-if="!item.offeredAmount"
                            @click.prevent="editOffer">{{ $t('MAKE_AN_OFFER') }}</button>
                    <div v-if="item.offeredAmount" class="mx-3">
                        Votre offre :
                        <a href="#" 
                           class="font-semibold text-red-900 text-xl link"
                           @click.prevent="editOffer">
                            {{ item.offeredAmount }} EUR
                        </a>
                        <!--button class="bg-gray-200 rounded-md px-2 py-1"
                                @click="editOffer">E</button-->
                        <button class="bg-gray-200 rounded-md ml-1 px-1 py-0 h-6 w-6"
                                @click="clearOffer">
                                <svg class="fill-current text-red-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"/></svg>
                                </button>
                    </div>
                </div>
                <div v-show="showOffer" class="mt-2">
                    <input class="bf-gray-200 font-semibold text-right"
                           ref="amount"
                           type="number"
                           v-model="offerAmount"
                           @keyup.enter="offerDone"
                           @keyup.esc="showOffer = false"
                           placeholder="Montant de l'offre...">
                    <button class=" w-12 rounded-lg bg-gray-200 text-green-500 py-1 px-3 ml-1 font-semibold"
                            type="submit"
                            :disabled="!offerAmount"
                            @click="offerDone">Ok</button>
                    <button class=" w-12 rounded-lg border border-gray-200 text-gray-700 py-1 px-3 ml-1 font-semibold"
                            @click="showOffer = false">
                        <svg class="fill-current text-red-800 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/></svg>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="item.videoLink && displayVideo" class="fixed top-0 left-0 h-screen w-full">
            <div class="fixed z-50 h-full w-full pt-10">
                <div class="fixed top-0 left-0 flex justify-between text-white">
                    <div class="px-4 py-2">{{ item.name }}</div>
                    <a href="#" class="fixed top-0 right-0 my-2 mx-4" @click.prevent="displayVideo = false">
                        <svg class="fill-current text-white h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/></svg>
                    </a>
                </div>
                <iframe class="w-full h-full" :src="item.videoLink" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="fixed z-40 h-full w-full  bg-black opacity-75"></div>
        </div>

        <popup-image v-if="showImage" :src="'/images/img-' + item.code + '.jpg'" :name="item.name" @close="showImage = false"></popup-image>

    </div>
</template>

<script>
import PopupImage from '../Utils/PopupImage.vue'

export default {
    name: "OfferItem",
    components: {
        PopupImage
    },
    props: ["item", "index"],
    data() {
        return {
            offerAmount: null,
            showOffer: false,
            displayVideo: false,
            showImage: false
        }
    },
    methods: {
        clearOffer() {
            this.$emit('update-offer', null);
        },
        offerDone() {
            this.$emit('update-offer', this.offerAmount);
            this.showOffer = false;
        },
        editOffer() {
            this.offerAmount = this.item.offeredAmount;
            this.showOffer = true;
            this.$nextTick(() => {
                this.$refs.amount.focus();
                this.$refs.amount.select();
            });
        },
        showVideo() {
            this.displayVideo = true;
        }
     }
}
</script>

<style scoped>
    .link {
        background-image: linear-gradient(180deg,transparent 70%,rgba(245, 242, 91, 0.4) 0);
    }
</style>