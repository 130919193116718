module.exports = {
  "en": {
    "MADE_BY": "Made by anvi",
    "MAKE_AN_OFFER": "Make a offer",
    "WELCOME": "Welcome!"
  },
  "fr": {
    "MADE_BY": "Réalisation anvi",
    "MAKE_AN_OFFER": "Faire une offre",
    "WELCOME": "Bienvenue !"
  }
};